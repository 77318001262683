/**
*
* -----------------------------------------------------------------------------
*
* Template : Echooling - Online Education React Template
* Author : reacthemes
* Author URI : https://reactheme.com/
*
* ------------------------------------------------

    CSS INDEX
    ===================

01. Theme default CSS
2. header
3. about
4. slider
5. footer
6. service
7. team
8. cta
9. project
10. testimonial
11. contact
12. carrer
13. blog
14.shop

-----------------------------------------------------------------------------------*/
@import "animations";
@import "keyframes";
@import "menu";
@import "design";
@import "responsive";
@import "custom-spacing";
@import "../fonts/flaticon";
@import "../fonts/elegant-icon";

.es-list {
  margin-bottom: 40px;
}

.es-list:last-child {
    margin-bottom: 0;
}

.es-list-item {
  background: "#00306e";
}

.es-list-item:hover {
  background-color: #3270fc;
}
